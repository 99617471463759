I18n.translations || (I18n.translations = {});
I18n.translations["en"] = I18n.extend((I18n.translations["en"] || {}), {
  "javascript": {
    "actions": {
      "cancel": "Cancel",
      "confirm": "Confirm",
      "copy": "copy",
      "submit": "Submit"
    },
    "confirmation": {
      "countdown": {
        "one": "You will be redirected after %{count} second...",
        "other": "You will be redirected after %{count} seconds..."
      },
      "messages": {
        "cancel": "Payment has been cancelled",
        "failure": "Your payment has been declined",
        "pending": "Your payment is pending",
        "success": "Your payment is accepted"
      },
      "to_website": "Back to merchant"
    },
    "modals": {
      "terms_of_use": {
        "title": "Terms of use"
      }
    },
    "options": {
      "or": "Or",
      "please_select": "Please select...",
      "select_day": "Select Day",
      "select_month": "Select Month",
      "select_year": "Select Year"
    },
    "qr_page": {
      "scan_qr_code": "Scan the QR Code to finalize your Pix transaction"
    },
    "turning_card": {
      "full_name": "Full Name",
      "month": "month",
      "thru": "thru",
      "valid": "valid",
      "year": "year"
    },
    "wpf_form": {
      "cnpj": {
        "label": "CNPJ",
        "placeholder": "xx.xxx.xxx/xxxx-xx"
      },
      "consumer_name": "Consumer name",
      "country_select_prompt": "Select country",
      "cpf": {
        "label": "CPF",
        "placeholder": "xxx.xxx.xxx-xx"
      },
      "cpf_cnpj": "CPF/CNPJ",
      "document_id": "Document",
      "mexico_banking_fields": {
        "document": {
          "label": "CURP",
          "placeholder": "AUBC740102HNTNRR44"
        }
      }
    }
  }
});
